p{
    font-size: 14px;
}

.Page{
    height: 100vh;
}

html{
    --border-color:#e2e2e2;
    --border:1px solid #e2e2e2;
    --color-accent:#4182d6;
}

::-webkit-scrollbar { width: 3px; height: 3px; }
::-webkit-scrollbar-button {width: 0px; height: 0px;  background-color: #666; }
::-webkit-scrollbar-track {  background-color: #999;}
::-webkit-scrollbar-track-piece { background-color: #ffffff;}
::-webkit-scrollbar-thumb { height: 50px; background-color: rgba(102, 102, 102, 0.26);; border-radius: 10px;}
::-webkit-scrollbar-corner { background-color: rgba(102, 102, 102, 0.26);;}
::-webkit-resizer { background-color: rgba(102, 102, 102, 0.26);}

.form-control{
    font-size: 13px;
}

input:active,
input:focus{
    outline: none !important;
}


a{
    text-decoration: none !important;
}

.unselectable {
   /**
             * Для эксплорера
             */
             -ms-user-select: none;

             /**
              * Для мозилы
              */
             -moz-user-select: none;
 
             /**
              * Для конкверора
              */
             -khtml-user-select: none;
 
             /**
              * Для Сафари и Хрома
              */
             -webkit-user-select: none;
  }
  
.btn{
    border-radius: 4px;
    border:none;
    font-size: 11px;
    font-weight: 300;
    text-transform: uppercase;
    padding: 6px 15px;
}

.form-control{
    border:1px #e9e9e9 solid;
    border-radius: 8px;
    background: rgb(252, 252, 252);
}

.border-danger{
    background: rgb(255, 240, 240);
    border:red 1px solid !important;
}

hr{
    background-color: rgba(0, 0, 0, 0.27);
}

.btn-primary{
    transition: .3s;
    background: var(--color-accent);
    opacity: 0.8;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus{
    background: var(--color-accent);
    opacity: 1;
}